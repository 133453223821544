import {Button, Flex, Grid, GridItem, IconButton, Text} from '@chakra-ui/react';
import {FC, memo, useCallback, useState} from 'react';
import {useT} from '@progress-fe/core';
import cn from 'clsx';

import {ISelectOption} from '../../../../../interfaces';
import {Svg} from '../../../../helpers';
import {InputNumber, Select} from '../../../../inputs';
import {JsFieldName} from '../../../../jsFormBase';
import {TOilSampleModelFormData, TRangeValue} from '../../OilSampleModelTableFormData.types';

import {OilSampleRange} from './components';
import s from './OilSampleModelTable.module.css';

interface IProps {
  ranges: TRangeValue[];
  formData: TOilSampleModelFormData;
  isReadOnly: boolean;
  isDialog: boolean;
  onCreateRange: (splitVal: string, idx: number) => void;
  onDeleteRange: (id: number) => void;
  onOptionValueChanged: (value: string | number) => void;
  onRangeChanged: (value: TRangeValue, idx: number) => void;
  onToggleDialog: () => void;
  suffix?: string;
}

const OilSampleModelTableFC: FC<IProps> = ({
  ranges,
  formData,
  suffix,
  isReadOnly,
  isDialog,
  onCreateRange,
  onDeleteRange,
  onOptionValueChanged,
  onRangeChanged,
  onToggleDialog
}) => {
  const {t} = useT();

  const [splitValue, setSplitValue] = useState<string | null>(null);
  const [invalidSplitValue, setInvalidSptitValue] = useState<boolean>(false);

  const fieldConfig = formData.fieldConfig;

  const handleChangeFormOptionValue = (option: ISelectOption<string | number>) => {
    onOptionValueChanged(option.value);
  };

  const handleChangeSplitValue = useCallback((value: string | null): void => {
    setInvalidSptitValue(false);
    setSplitValue(value);
  }, []);

  const handleCreateRange = (): void => {
    if (splitValue === null) {
      return;
    }

    const splitNumVal = Number(splitValue);

    const rangeIdxToSplit = ranges.findIndex((r) => {
      return (
        Number(r[fieldConfig.min.name]) < splitNumVal &&
        Number(r[fieldConfig.max.name]) > splitNumVal
      );
    });

    if (rangeIdxToSplit < 0) {
      setInvalidSptitValue(true);
      return;
    }

    setSplitValue(null);
    onCreateRange(splitValue, rangeIdxToSplit);
  };

  const handleDeleteRange = (idx: number): void => {
    onDeleteRange(idx);
  };

  const handleRangeChanged = (rangeVal: TRangeValue, idx: number) => {
    onRangeChanged(rangeVal, idx);
  };

  const handleDialogClick = useCallback(() => {
    onToggleDialog();
  }, [onToggleDialog]);

  const fieldsTitles = [fieldConfig.min.title, fieldConfig.max.title].concat(
    formData.fieldConfig.fields.map((f) => f.title)
  );

  return (
    <Flex
      p="4px 0"
      flexDirection="column"
      gap="8px"
      className={cn(s.oilSampleModelTable, isDialog && s.dialog)}
    >
      {/* SELECT FIELD */}
      <Grid gridTemplateColumns={`minmax(0, 155px) 1fr`} alignItems="center">
        <JsFieldName name={fieldConfig.title} />
        <Select
          name="model"
          options={fieldConfig.options}
          isDisabled={isReadOnly}
          placeholder={t('common.notSelected')}
          value={fieldConfig.options.find((opt) => opt.value === formData.fieldValues.optionValue)}
          onChange={(newValue) => {
            handleChangeFormOptionValue(newValue as ISelectOption<string | number>);
          }}
        />
      </Grid>
      {/* RANGES */}
      <Grid
        gridTemplateColumns={`155px 1fr`}
        borderRadius="4px"
        border="1px"
        borderColor="border"
        overflow="hidden"
      >
        <Grid
          borderRight="1px solid"
          borderColor="border"
          gap="4px"
          p="4px 0 4px 4px"
          alignItems="center"
          autoRows="24px"
        >
          {fieldsTitles.map((title) => (
            <Text key={title}>{title}</Text>
          ))}
          <Text>{t('jsfields.OilSampleModelTable.deleteRange')}</Text>
        </Grid>
        <GridItem overflow="hidden">
          <Flex overflow="auto" gap="4px" p="4px 0 4px 4px">
            {ranges.map((range, idx) => (
              <OilSampleRange
                isReadOnly={isReadOnly}
                className={s.rangeColumn}
                key={idx}
                rangeValue={range}
                formData={formData}
                suffix={suffix}
                isFirst={idx === 0}
                isLast={idx === ranges.length - 1}
                onDelete={() => handleDeleteRange(idx)}
                onChange={(val) => handleRangeChanged(val, idx)}
              />
            ))}
          </Flex>
        </GridItem>
      </Grid>
      {/* ADDING A NEW RANGE */}
      <Flex gap="4px">
        <InputNumber
          size="xs"
          variant="outline"
          sx={{width: '100%'}}
          value={splitValue ?? undefined}
          isInvalid={invalidSplitValue}
          min={fieldConfig.min.value}
          max={fieldConfig.max.value}
          isDisabled={isReadOnly}
          onChange={handleChangeSplitValue}
        />
        <Button
          variant="ghost"
          size="smSquare"
          width="100%"
          onClick={handleCreateRange}
          isDisabled={isReadOnly || splitValue === null || invalidSplitValue}
        >
          {t('jsfields.OilSampleModelTable.addRange')}
        </Button>
        {!isDialog && !isReadOnly && (
          <IconButton
            flexShrink={0}
            size="smSquare"
            aria-label=""
            variant="ghost"
            icon={<Svg size="s12" name="Expand" />}
            onClick={handleDialogClick}
          />
        )}
      </Flex>
    </Flex>
  );
};

export const OilSampleModelTable = memo(OilSampleModelTableFC);
