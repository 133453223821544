import {tableAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  th: {
    textTransform: 'none',
    fontWeight: '400'
  },
  td: {
    pl: '4px'
  }
});

export const tableTheme = defineMultiStyleConfig({baseStyle});
