import {memo, useContext, useEffect, useMemo, useState} from 'react';
import {FieldProps} from '@rjsf/utils';
import {Box} from '@chakra-ui/react';
import {hasValue, useT} from '@progress-fe/core';
import _ from 'lodash-es';

import {Dialog} from '../../helpers';
import {JsFieldError} from '../../jsFormBase';
import {useJsFieldFormData} from '../../../hooks';
import {JsFormConfigContext} from '../../../contexts';

import {BinaryCoeffsTable} from './components';

type TFormDataConfig = {
  callbackCode: string | null;
};

type TFormDataFieldValue = Record<string, Record<string, string | null>>;

type TFormData = {
  fieldConfig: TFormDataConfig;
  fieldValues: TFormDataFieldValue;
};

/**
 * Component renders "/schemas/jsf-binary-coeffs" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is TFormData.
 */
const BinaryCoeffsJsFieldFC = (props: FieldProps) => {
  const {components, runModelCallback} = useContext(JsFormConfigContext) || {};
  const {pureList = []} = components || {};

  const [isDialog, setIsDialog] = useState(false);

  const {t} = useT();

  const formData = useMemo(
    () => (hasValue(props.formData) ? (props.formData as TFormData) : null),
    [props.formData]
  );

  const {fieldConfig, fieldValues, onChangeFieldValues, onChangeFieldConfig} = useJsFieldFormData({
    config: formData?.fieldConfig || null,
    values: formData?.fieldValues || null,
    onChange: props.onChange
  });

  useEffect(() => {
    onChangeFieldConfig(formData?.fieldConfig);
  }, [formData?.fieldConfig, onChangeFieldConfig]);

  const handleChangeValue = (rootKey: string, key: string, value: string | null) => {
    if (!!fieldValues) {
      const fieldValuesCopy = _.cloneDeep(fieldValues);
      fieldValuesCopy[rootKey][key] = value;
      fieldValuesCopy[key][rootKey] = value;
      onChangeFieldValues(fieldValuesCopy);
    }
  };

  const handleBipsCallback = async () => {
    const formDataResult = await runModelCallback?.(fieldConfig?.callbackCode || '');
    if (!!hasValue(formDataResult)) {
      onChangeFieldConfig((formDataResult as TFormData).fieldConfig);
      onChangeFieldValues((formDataResult as TFormData).fieldValues);
    }
  };

  const handleToggleDialog = () => {
    setIsDialog(!isDialog);
  };

  if (!fieldConfig || !fieldValues) {
    return <JsFieldError propName={props.name} />;
  }

  return (
    <Box w={isDialog ? '100%' : '423px'}>
      <BinaryCoeffsTable
        isDialog={isDialog}
        pureList={pureList}
        fieldValues={fieldValues}
        isReadOnly={props.schema.readOnly}
        hasCallback={!!fieldConfig.callbackCode}
        onChangeValue={handleChangeValue}
        onCalcCoeffs={handleBipsCallback}
        onToggleDialog={handleToggleDialog}
      />

      {isDialog && (
        <Dialog title={t('jsfields.BinaryCoeffs.name')} onClose={handleToggleDialog}>
          <BinaryCoeffsTable
            isDialog={isDialog}
            pureList={pureList}
            fieldValues={fieldValues}
            isReadOnly={props.schema.readOnly}
            hasCallback={!!fieldConfig.callbackCode}
            onChangeValue={handleChangeValue}
            onCalcCoeffs={handleBipsCallback}
            onToggleDialog={handleToggleDialog}
          />
        </Dialog>
      )}
    </Box>
  );
};

export const BinaryCoeffsJsField = memo(BinaryCoeffsJsFieldFC);
